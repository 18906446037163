.container {
  max-width: 1400px;
  margin:  auto;
  padding: 20px;
  margin-top: 105px;
}

.title {
  font-size: 20px; /* Adjust the font size for h1 and h2 */
}

.subtitle {
  font-size: 16px; /* Adjust the font size for subtitles */
}

@media screen and (max-width: 768px) {
  .container {
    padding: 10px;
  }
}

.policy-section {
  margin-bottom: 1em;
}

.policy-section h2 {
  margin: 0;
  color: rgb(36, 135, 234);
  font-size: 18px;
}

.subtitle {
  margin: 0;
  color: black; 
  font-size: 16px;
}

.notice {
  color: red;
  font-weight: bold;
}

.notice {
  color: red;
  font-weight: bold;
}

.document-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.document-table th, .document-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.document-table th {
  background-color: #080808;
  color: white;
  text-align: left;
}

.document-table td a {
  color: #007BFF;
  text-decoration: none;
}

.document-table td a:hover {
  text-decoration: underline;
}
