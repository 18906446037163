.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0;
}

.box {
  width: 100% !important;
  display: flex;
  align-items: center;
  padding: 20px;
  background-image: url("/public/images/credentials-page/Cert-hero.png");
  background-position: 95%;
  background-size: 50vw 35vw;
  background-repeat: no-repeat;
  min-height: 100vh;
}

.textGroup {
  flex: 1;
  margin-right: 20px;
  margin-left: 5rem;
}

.heading {
  color: #000000;
  font-family: "Poppins-ExtraBold", Helvetica;
  font-size: 4vw;
  font-weight: 800;
}

.textWrapper {
  color: #000000;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 2vw;
  font-weight: 600;
  width: 35%;
}

.credentialsPageMainHeader {
  text-align: center;
  margin: 2vw 0;
  color: hsl(204, 74%, 62%);
}

.certification-header {
  margin-top: 12%;
}

.carouselItem {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 10%;
  width: 100% !important;
}

.badgeContainer {
  background-color: #fdfdfd;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15vh;
  height: 15vh;
  border-radius: 10%;
  border: 1px solid #00000011;
  filter: drop-shadow(3px 3px 1px rgba(0, 0, 0, 0.308));
}

.badgeContainer:hover {
  transform: scale(1.05);
  transition: transform 0.3s;
}

.badgeContainer img {
  width: 100%;
}

.credentialsPageContent {
  width: 100%;
  min-height: 100vh;
  gap: 8vh;
  display: flex;
  flex-direction: column;
}

.credentialsSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5em;
  width: 100%;
}

.carouselCards {
  display: flex;
  justify-content: center;
  align-self: center;
  width: 100%;
  gap: 5%;
}

.carouselCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
  padding: 1em;
  border-radius: 1em;
  width: 30%;
  align-self: flex-start;
}

.credentialsPageHeaders {
  display: flex;
  justify-content: center;
  gap: 1em;
}

.credentialsPageHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
  text-align: center;
  width: 100%;
}

.credentialsPageHeader h1 {
  font-size: 6em;
  font-weight: 800;
  color: black;
}

.credentialsPageHeader h2 {
  font-size: 4.5em;
  font-weight: 600;
  color: black;
}

.credentialsPageHeader h3 {
  font-size: 3em;
  font-weight: 600;
  color: #247dff;
}

.credentialsButtonHeader {
  margin: 1vw 0;
  font-weight: 500;
  font-size: large;
}

.credentialsPageCards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1em;
  position: relative;
  margin: 0 !important;
}

.credentialsPageCardContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 600px;
  width: 375px;
  min-height: 320px;
  min-width: 200px;
}

.credentialsPageDescription {
  margin: 0 auto;
  font-size: x-large;
  color: white;
  width: 68%;
  padding-bottom: 2vw;
}

.credentialsPageCardBody {
  background-color: white;
  height: 100%;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
  border: 1px solid #103f6d;
  border-radius: 2rem;
  padding: 2%;
}

.credentialsPricingHeader {
  font-size: 150%;
  font-weight: 500;
}

.credentialsPricingButton {
  margin-top: 0.75vh;
  background-color: #103f6d;
  color: white;
  width: 55%;
  padding: 0.5em 1em;
  border-radius: 1em;
  font-size: 1em;
  font-weight: bold;
  cursor: pointer;
  min-width: 6em;
  min-height: 3em;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}

.credentialsPricingButton:hover {
  background-color: #0d2e52;
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.6);
}

.credentialsPageCardBody li {
  font-size: large;
}

.carouselCert {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
  height: unset !important;
}

.carousel .carousel-item {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 1em;
  overflow: visible !important;
}

.certFlex {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1em;
}

.certItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
  height: 80%;
  width: 25%;
  text-align: center;
}

.certItem h4 {
  font-size: 2em;
  font-weight: 800;
}

.certItem p {
  font-size: 1.3em;
  font-weight: 600;
  color: #787575;
}

.certIcon {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.certIcon img {
  width: 60px;
  height: 60px;
}
/* background-position: 95%;
background-size: 50vw 35vw;
background-repeat: no-repeat; */
@media screen and (max-width: 1330px) {
  .box {
    background-size: 55vw 50vh;
    min-height: 80vh;
  }
  .textWrapper {
    font-size: 2.5vw;
  }
  .title {
    font-size: 4rem;
  }
  .carouselCards {
    gap: 2%;
  }
  .carouselCard{
    width: 40%;
  }
}

@media screen and (max-width: 1024px) {
  .box {
    background-size: 55vw 45vh;
    min-height: 80vh;
  }
  .textGroup {
    margin-left: 0;
  }
  .textWrapper {
    font-size: 2.5vw;
  }
  .title {
    font-size: 4rem;
  }
  .carouselCards {
    gap: 2%;
  }
  .carouselCard{
    width: 45%;
  }
  .carouselCert {
    width: 100%;
    min-height: 40vh;
  }
  .carouselItem {
    gap: 2em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 75%;
    flex-wrap: wrap;
  }
  .certFlex {
    gap: 2em;
  }
  .certItem {
    width: 40%;
  }
  .certItem h4 {
    font-size: 2.5em;
  }
  .certItem p {
    font-size: 1.5em;
  }

}

@media screen and (max-width: 768px) {
  .credentialsPageHeader h2{
    font-size: 3em;
  }
  .carouselCard{
    width: 90%;
  }
  .box {
    background-size: 50vh 40vh;
    background-position: center bottom;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .textGroup {
    flex: 0;
    text-align: center;
    white-space: nowrap;
    margin-bottom: 25vh;
  }
  .textWrapper {
    font-size: 4vw;
    text-align: center;
    width: 100%;
  }
  .heading {
    font-size: 8vw;
  }
  .carouselCert {
    width: 100%;
    min-height: 50vh;
  }
  .carouselItem {
    gap: 2em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 75%;
    flex-wrap: wrap;
  }
  .certFlex {
    gap: 2em;
  }
  .certItem {
    width: 60%;
  }
  .certItem h4 {
    font-size: 2.5em;
  }
  .certItem p {
    font-size: 1.5em;
  }
}

@media screen and (max-width: 425px) {
}

@media screen and (max-width: 355px) {
  .credentialsHeroSection {
    height: 50vw !important;
  }

  .credentialsHeroHeader {
    text-shadow: 2px 2px rgba(1, 1, 1, 0.5);
    font-size: 6vw;
  }

  .credentialsHeroSubHeader {
    font-weight: 600;
    color: #188efc;
    text-shadow: 2px 2px rgba(1, 1, 1, 0.5);
    font-size: 5vw;
  }

  .credentialsContent {
    padding: 2em;
    text-align: center;
  }

  .credentialHeader {
    font-size: 6vw;
  }

  .credentialDescription {
    font-size: 4vw;
  }

  .credentialPricings {
    flex-direction: column;
    gap: 1em;
  }

  .pricingHeader {
    font-size: 7vw;
  }

  .credentialPricingContent h5 {
    font-size: 6vw;
  }

  .credentialPricingContent ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5em;
    width: 100%;
  }

  .credentialLeftSide,
  .credentialRightSide {
    gap: 0;
    width: 100%;
  }

  .credentialPricingContent {
    width: 100%;
  }
}

@media screen and (min-width: 356px) and (max-width: 768px) {
  .credentialsPageDescription {
    font-size: x-large;
  }

  .credentialsButtonHeader {
    font-size: 80%;
  }
  .credentialsPageCardContent {
    height: 700px;
    min-height: 320px;
  }

  .credentialsHeroSection {
    height: 50vw !important;
  }

  .credentialsHeroHeader {
    text-shadow: 2px 2px rgba(1, 1, 1, 0.5);
    font-size: 6vw;
  }

  .credentialsHeroSubHeader {
    font-weight: 600;
    color: #188efc;
    text-shadow: 2px 2px rgba(1, 1, 1, 0.5);
    font-size: 5vw;
  }

  .credentialsContent {
    padding: 2em;
    text-align: center;
  }

  .credentialHeader {
    font-size: 6vw;
  }

  .credentialDescription {
    font-size: 4vw;
  }

  .credentialPricings {
    flex-direction: column;
    gap: 2em;
  }

  .pricingHeader {
    font-size: 7vw;
  }

  .credentialPricingContent h5 {
    font-size: 6vw;
  }

  .credentialPricingContent ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5em;
    width: 100%;
  }

  .credentialPricingContent li {
    font-size: 5vw;
  }

  .credentialLeftSide,
  .credentialRightSide {
    gap: 1em;
    width: 100%;
  }

  .credentialPricingContent {
    width: 100%;
    gap: 1em;
    text-align: center;
  }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
  .credentialsHeroSection {
    height: 50vw !important;
  }

  .credentialsHeroHeader {
    text-shadow: 2px 2px rgba(1, 1, 1, 0.5);
    font-size: 6vw;
  }

  .credentialsHeroSubHeader {
    font-weight: 600;
    color: #188efc;
    text-shadow: 2px 2px rgba(1, 1, 1, 0.5);
    font-size: 5vw;
  }

  .credentialsContent {
    padding: 4em;
    text-align: center;
  }

  .credentialHeader {
    font-size: 6vw;
  }

  .credentialDescription {
    font-size: 3.5vw;
  }

  .credentialPricings {
    gap: 2em;
  }

  .pricingHeader {
    font-size: 4vw;
  }

  .credentialPricingContent h5 {
    font-size: 3.5vw;
  }

  .credentialPricingContent ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5em;
    width: 100%;
  }

  .credentialPricingContent li {
    font-size: 3vw;
  }

  .credentialLeftSide,
  .credentialRightSide {
    gap: 1em;
    width: 100%;
  }

  .credentialPricingContent {
    width: 100%;
    gap: 1em;
  }
}

@media screen and (max-width: 768px) {
  .certification-header {
    margin-top: 20%;
  }
}

@media screen and (max-width: 500px) {
  .credentialsPageHeaders {
    gap: 0;
  }

  button.credentialsPageHeader {
    width: 100%;
    border: 1px solid black;
    /* border-radius: 20px 20px 0 0; */
    height: 20vw;
    margin: -3vw 0;
    padding: 0 0px !important;
  }

  .credentialsButtonHeader {
    font-size: 0.8rem;
    font-weight: 400;
    margin: auto;
  }
  .credentialsPageMainHeader {
    margin-bottom: 10%;
  }

  .credentialsPageDescription {
    margin-top: 1rem;
    font-size: 3.4vw;
    /*display:none;*/
  }

  .credentialsPageCards {
    margin: 0 8vw;
  }

  .credentialsPricingHeader {
    font-size: 5vw;
  }

  .credentialsPageCardBody li {
    font-size: 3.3vw;
  }

  .credentialsPageCardHeader {
    font-size: 3.5vw;
  }

  .credentialsPageCardBody {
    margin: 0 1.2vw;
    height: auto;
    padding-top: 12vw;
  }

  .credentialsPageCardContent {
    height: auto;
  }

  .certification-header {
    margin-top: 20%;
  }
}
