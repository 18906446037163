*{
  font-family: 'Roboto', sans-serif;
}
.carousel-control-prev, .carousel-control-next {
  width: 5%;
  padding: 0;
}
.carousel-inner{
  overflow: visible !important;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 30px;
  height: 30px;
}