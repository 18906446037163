
.credentialsPageCardHeader {
  background-image: url("/public/images/credentials-page/JS-card.png");
  background-position: center 60%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-height: 30vh;
  width: 100%;
}

.priceChip{
  position: absolute;
  top: 5%;
  right: 3%;
  background-color: white !important;
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.308));
  font-size: 1rem;
  font-weight: 600;
}

.credentialsPageCardBody {
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #00000062;
  filter: drop-shadow(1px 3px 3px rgba(0, 0, 0, 0.308));
  border-radius: 2rem;
  overflow: hidden;
  padding-bottom: 2em;
  align-self: center !important;
  position: relative;
}
.credentialsPageCardBodyUnavailable{
  width: 100%;
  height: 100%;
  z-index: 1 !important;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #0000008f;
  color: white;
  text-align: center;
  font-weight: 600;
}

.credentialsPageCardBody h3{
  font-size: 1.5em;
  font-weight: bold;
  color: #000000;
  text-align: center;
}
.cardTextContent{
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1em;
  gap: 1em;
  min-height: 200px;
}

.cardTextContent ul{
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: .5em;
}

.cardTextContent ul li{
  font-size: 1em !important;
  font-weight: 200;
}

.credentialsPricingButton {
  min-width: 40%; 
  padding: 0 1em !important;
  border-radius: 0 !important;
  border-top-left-radius: .2rem !important;
  border-top-right-radius: .2rem !important;
  font-size: 1em;
  font-weight: bold;
  cursor: pointer;
  min-width: 6em;
  min-height: 1em;
  white-space: nowrap;
}

.popOver{
  text-align: center;
  margin: 0;
  padding: 1rem; 
}
@media screen and (max-width: 1330px) {
  .credentialsPageCardHeader {
    background-size: 100% 100%;
    min-height: 35vh;
  }
}

@media screen and (max-width: 1024px) {
  .credentialsPageCardHeader {
    background-size: 100% 100%;
    min-height: 40vh;
  }

}

@media screen and (max-width: 768px) {
  .credentialsPageCardHeader {
    background-size: 100% 100%;
    min-height: 40vh;
  }
}

@media screen and (max-width: 425px) {
  
}
