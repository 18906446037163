.container {
    max-width: 1400px; 
    margin: 0 auto;
    padding: 20px;
    margin-top: 10% !important;
  }
  
  .content {
    margin-top: 30px;
  }
  
  @media screen and (max-width: 768px) {
    .container {
      padding: 10px;
      margin-top: 20% !important;
    }
  }
  
  .highlighted-text-red {
    color: red;
  }
  
  
