.examContainer {
  font-family: 'Roboto', Arial, sans-serif;
  padding: 20px;
  max-width: 1000px;
  margin: auto;
  margin-top: 50px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-top: 10%;
}

.examContainer h1 {
  color: #222;
  text-align: left;
  font-size: 36px;
  margin-top: 60px;
  margin-bottom: 10px;
}

.examContainer h2 {
  color: #444;
  text-align: left;
  margin-top: 40px;
  margin-bottom: 20px;
  font-size: 28px;
}

.examContainer h3 {
  color: #555;
  text-align: left;
  margin-top: 30px;
  margin-bottom: 15px;
  font-size: 22px;
}

.examContainer p {
  line-height: 1.6;
  margin-bottom: 20px;
  color: #555;
}

.examContainer ul {
  list-style-type: disc;
  margin-left: 20px;
  color: #555;
}

.examContainer table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  background-color: #fff;
}

.examContainer table,
.examContainer th,
.examContainer td {
  border: 1px solid #ddd;
}

.examContainer th,
.examContainer td {
  padding: 12px;
  text-align: left;
}

.examContainer th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.examContainer section {
  margin-bottom: 40px;
}

.examContainer .no-bullets {
  list-style-type: none;
  padding-left: 0;
}

.examContainer .no-bullets > li {
  list-style-type: none;
}

.examContainer .learnMoreButton {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007BFF;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.examContainer .learnMoreButton:hover {
  background-color: #0056b3;
}

.examContainer .certification-details {
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-top: 20px;
}

.examContainer .certification-details h2 {
  font-size: 24px;
  margin-top: 0;
}

.examContainer .certification-details h3 {
  font-size: 20px;
  margin-top: 20px;
}

.examContainer .certification-details p {
  margin-bottom: 15px;
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
  .examContainer {
    padding: 15px;
    margin-top: 20px;
  }

  .examContainer h1 {
    font-size: 28px;
  }

  .examContainer h2 {
    font-size: 24px;
    margin-top: 30px;
    margin-bottom: 15px;
  }

  .examContainer h3 {
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .examContainer p {
    margin-bottom: 15px;
  }

  .examContainer th,
  .examContainer td {
    padding: 10px;
  }

  .examContainer .learnMoreButton {
    font-size: 14px;
    padding: 8px 16px;
  }

  .examContainer .certification-details h2 {
    font-size: 20px;
  }

  .examContainer .certification-details h3 {
    font-size: 18px;
  }

  .examContainer .certification-details p {
    margin-bottom: 10px;
  }
}

@media (max-width: 480px) {
  .examContainer {
    padding: 10px;
  }

  .examContainer h1 {
    font-size: 24px;
  }

  .examContainer h2 {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .examContainer h3 {
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 8px;
  }

  .examContainer p {
    margin-bottom: 10px;
  }

  .examContainer th,
  .examContainer td {
    padding: 8px;
  }

  .examContainer .learnMoreButton {
    font-size: 12px;
    padding: 6px 12px;
  }

  .examContainer .certification-details h2 {
    font-size: 18px;
  }

  .examContainer .certification-details h3 {
    font-size: 16px;
  }

  .examContainer .certification-details p {
    margin-bottom: 8px;
  }
}
