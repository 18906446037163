.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0;
}

.box {
  width: 100% !important;
  display: flex;
  align-items: center;
  padding: 20px;
  background-image: url("/public/images/partners-images/partnersCover.png");
  background-position: 95%;
  background-size: 50vw 35vw;
  background-repeat: no-repeat;
  min-height: 90vh;
}

.textGroup {
  flex: 1;
  margin-right: 20px;
  margin-left: 5rem;
}

.partnerWithCoitb {
  color: #000000;
  font-family: "Poppins-ExtraBold", Helvetica;
  font-size: 4vw;
  font-weight: 800;
}

.textWrapper {
  color: #000000;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 1.5vw;
  font-weight: 600;
  width: 35%;
}

.partnerContainer {
  margin: 2%;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Creates three columns; adjust if necessary */
  gap: 4rem; /* Adjusts space between cards */
  align-items: stretch;
  gap: 2rem;
  width: 90%;
}

.partnerSection {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0;
}

.contactContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 0;
  height: 90vh;
  background-color:#055487;
}

.form {
  width: 50%;
  height: 100%;
  flex-grow: 1;
}

.formContent {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin: 0;
  height: 100%;
  gap: 2rem;
}

.formContent img {
  margin-top: 10%;
  width: 30vw;
  height: 15vw;
}

.formContent h2 {
  width: 70%;
  color: #fff;
  font-family: "Poppins-ExtraBold", Helvetica;
  font-size: 4.5vw;
  font-weight: 800;
  text-align: right;
}

@media screen and (max-width: 1330px) {
  .formContent {
    gap: 2.5rem;
  }

  .formContent img {
    width: 40vw;
    height: 20vw;
  }

  .formContent h2 {
    width: 90%;
    font-size: 6vw;
  }

  .box {
    background-size: 60vw 40vw;
    min-height: 70vh;
  }
}

@media screen and (max-width: 1024px) {
  .form {
    width: 100%;
  }

  .formContent {
    display: none;
  }

  .box {
    background-size: 55vw 35vw;
    min-height: 60vh;
  }

  .partnerContainer {
    width: 90%;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .box {
    align-items: flex-start;
    background-size: 90vw 55vw;
    min-height: 80vh;
    background-position: center bottom;
  }

  .textGroup {
    flex: 1 1;
    text-align: center;
    margin: 0;
    margin-top: 15vh;
    gap: 2vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .textWrapper {
    width: 100%;
    text-align: center;
    font-size: 1rem;
  }

  .partnerWithCoitb {
    font-size: 2rem;
  }

  .partnerContainer {
    width: 100%;
    padding: 2%;
    grid-template-columns: 1fr;
  }

  .partnerSection {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

@media screen and (max-width: 425px) {
  .box {
    background-size: 95vw 50vw;
    min-height: 70vh;
  }

  .textGroup {
    margin-top: 18vh;
  }

  .textWrapper {
    font-size: 0.8rem;
  }

  .partnerWithCoitb {
    font-size: 1.2rem;
  }
}
