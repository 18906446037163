.card{
    filter: drop-shadow(1px 3px 2px rgba(0, 0, 0, 0.5));
    border: 1px solid rgb(184, 184, 184);
    background-color: #FDFDFD;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    min-height: 40vh; /* You might need to adjust or remove this depending on your content */
    padding: 1%;
    transition: transform 0.5s;
}

.card:hover{
    transform: scale(1.01);
    transition: all 0.5s;
}

.cardHeader{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 2%;
    width: 100%;
}

.cardHeader img{
    width: 10vh;
    height: 10vh;
    border-radius: 50%;
}

.cardBody{
    padding: 3%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-weight: 500;
    padding-bottom: 6%;
}

.partnerLink{
    text-decoration: none;

}

.partnerLink h2{
    font-weight: 500;
}

@media screen and (max-width: 768px) {
    .card{
        width: 100%;
    }
}
