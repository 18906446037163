

.testing-page {
    margin-top: 150px; /* Space between the navbar and the first div */
    padding-left: 20px; /* Padding to move the content away from the left edge */
    padding-right: 20px; /* Optional: padding to move the content away from the right edge */
    max-width: 800px; /* Optional: to control the width of the content */
    margin-left: auto; /* Center the content */
    margin-right: auto; /* Center the content */
  }
  
  .testing-page h1,
  .testing-page h2,
  .testing-page h3,
  .testing-page h4,
  .testing-page p,
  .testing-page ul {
    margin-bottom: 20px; /* Add some space between elements */
  }
  
  .testing-page button {
    display: block;
    margin: 20px 0;
  }
  