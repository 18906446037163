.blogPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em;
}

.blogHeader {
  position: relative;
  width: 100%;
  max-width: 1300px;
  height: 80vh;
  margin-bottom: 2em;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Align children to opposite ends */
  overflow: hidden;
  padding: 0 2em;
}
.card{
  cursor: pointer;
}

.card:hover{
  transform: scale(1.05);
  transition: transform 0.5s;
}

.blogHeaderImage {
  width: 500px;
  height: auto;
  max-height: 100%;
  object-fit: cover;
  z-index: -1;
  margin-right: 30px;
}

.blogHeaderTitle {
  color: rgb(0, 0, 0);
  font-size: 6rem !important;
  font-weight: 600 !important;
  filter: drop-shadow(3px 2px 3px rgba(0, 0, 0, 0.5));
}

.blogSection {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.box {
  position: relative;
  width: 1300px;
}

.box .blogCards {
  position: relative;
  width: 100%;
}

.box .blogCardsRow {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2em;
}
.overlap{

}

.box .cardColumn {
  display: flex;
  justify-content: center;
}

.box .card {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 1em;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 300px;
}

.box .overlapGroup {
  position: relative;
}

.box .overlap {
  background-size: 100% 100%;
  height: 200px;
  border-radius: 5px;
  background-color: #6c757d;
  background-position: center;
  background-repeat: no-repeat;
}

.box .frame {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  padding: 5px 10px;
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.box .textWrapper {
  color: #ffffff;
  font-family: "Roboto-Bold", Helvetica, sans-serif;
  font-size: 10px;
  font-weight: 700;
  text-align: center;
}

.box .div {
  color: #495057;
  font-family: "Lora-Bold", Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 700;
  margin-top: 1em;
}

.box .textWrapper2 {
  color: #6c757d;
  font-family: "Lora-Regular", Helvetica, sans-serif;
  font-size: 12px;
  font-weight: 400;
  margin: 0.5em 0;
}

.box .p {
  color: #6c757d;
  font-family: "Lora-Regular", Helvetica, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
}

.box .div2 {
  color: #495057;
  font-family: "Lora-Bold", Helvetica, sans-serif;
  font-size: 12px;
  font-weight: 700;
  margin-top: 1em;
}

.box .span {
  color: #495057;
  font-weight: 700;
}

.box .textWrapper3 {
  color: #343a40;
  font-weight: 700;
}

.box .textWrapper4 {
  color: #6c757d;
  font-family: "Lora-Regular", Helvetica, sans-serif;
}

.blogCard {
  display: flex;
  flex-direction: column;
  /* background-color: #f0f0f0; */
  border-radius: 8px;
  overflow: hidden;
  align-items: center;
  padding: 16px;
}

.blogCard .thumbnail {
  width: 100%;
  max-width: 340px;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 16px;
}

.blogCard .cardContent {
  width: 100%;
}

.blogCard .cardTitle {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 8px;
}

.blogCard .cardDescription {
  font-size: 1rem;
  color: #757575;
  margin-bottom: 16px;
}

.blogCard .cardFooter {
  display: flex;
  justify-content: space-between;
}

.parent {
  min-height: 100vh;
  margin-top: 10%;
  margin-bottom: 5%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.blogContainer {
  width: 80%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.blogPost {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 5%;
}
.postTitle {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1vw;
}
.postContent {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5%;
}

.header {
  width: 80%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: flex-start;
}

.headerInfo {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1vw;
  margin-bottom: 2%;
}

.headerInfo h4 {
  color: #2c7cc5;
  margin: 0;
}

.headerInfo p {
  margin: 0;
}

.title {
  width: 80%;
  margin-bottom: 2%;
}

.headerImage {
  width: 100%;
  max-height: 60vh;
}

.contentMain {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 80%;
}

.sideBar {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 2%;
}

.socialIcons {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 10px;
  font-size: 200%;
  margin-right: 5%;
}

.mainContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2%;
}
.imageGallery {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.blogImage {
  width: 25vh;
  height: 18vh;
}

@media screen and (max-width: 1330px) {
  .contentMain {
    margin-top: 2%;
    flex-direction: column;
  }
  .sideBar {
    flex-direction: column-reverse;
  }
  .socialIcons {
    flex-direction: row;
    margin-bottom: 5%;
  }
  .imageGallery {
    flex-direction: row;
    margin-bottom: 5%;
  }
}

@media screen and (max-width: 1024px) {
  .blogPage {
    margin-top: 10vh;
  }
  .parent {
    margin-top: 12%;
  }
  .blogHeader {
    margin-top: 5%;
    flex-direction: column;
    text-align: center;
    height: auto;
  }
  .blogHeaderImage {
    width: 60%;
    height: auto;
    margin-right: 0;
  }
  .blogCards{
    width: 100%;
    margin-top: 5%;
  }
  .blogCardsRow {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

@media screen and (max-width: 768px) {
  .parent {
    margin-top: 20%;
  }

  .blogHeaderImage {
    width: 100%;
  }

  .blogImage {
    width: 20vh;
    height: 15vh;
  }

  .blogHeaderTitle{
    font-size: 4rem !important;
  }

  .blogCards{
    margin-top: 8%;
  }

  .blogCardsRow {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}

@media screen and (max-width: 425px) {

}
