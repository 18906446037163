.quiz-app {
    margin-top: 1rem;
    margin-bottom: 2rem;
    font-family: Arial, sans-serif;
    padding-top: 100px;
    padding-bottom: 50px;
    padding-inline: 10px;
    max-width: 600px;
    height: 100vh;
    margin: 0 auto;
    /* background-color: #5f93d678;   */
    background: linear-gradient(45deg,#7da5d846, #5da0f89c);
    /* background-color: linear-gradient(to bottom, #f0f0f0, #b0b0b0, #333333); */
    h1 { 
      text-align: center;
    }
    
    button {
      padding: 10px;
      margin: 5px;
      border: none;
      cursor: pointer;
      border-radius: 5px;
      background-color: white;
    }
    
    button:disabled {
      opacity: 0.5;
    }
    
    button:hover {
      background-color: #f0f0f0;
    }
    
    div {
      text-align: center;
    }
    
    h2 {
      margin-bottom: 20px;
    }
    
    p {
      font-size: 1.2rem;
      font-weight: bold;
    }
  }
  

  
  .challenge_content{
     padding-top: 50px;
  }


  /* Container style to keep the content centered and responsive */
.career-launch-container {
  max-width: 100%;
  padding: 10px;
  margin: 0 auto;
  box-sizing: border-box;
  text-align: center;
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

.title {
  font-size: 1.8rem;
  margin-bottom: 20px;
}

.intro {
  font-size: 1.2rem;
  margin-bottom: 20px;
  text-align: left;
}

.features-list {
  list-style-type: none;
  padding: 0;
  font-size: 1.1rem;
  margin-bottom: 20px;
  text-align: left;
}

.features-list li {
  margin-bottom: 10px;
}

.cta {
  font-size: 1.3rem;
  font-weight: bold;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .quiz-app{
    padding-top: 100px;
  }
  .title {
    font-size: 1.5rem;
  }

  .intro {
    font-size: 1rem;
  }

  .features-list {
    font-size: 1rem;
  }

  .cta {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 480px) {
    
  .title {
    font-size: 1.3rem;
  }

  .intro {
    font-size: 0.9rem;
  }

  .features-list {
    font-size: 0.9rem;
  }

  .cta {
    font-size: 1rem;
  }
}

@media screen and (max-width: 375px){
    .quiz-app{
        height: 110vh;
        padding-top: 50px;
    }
    .challenge_content{
        padding-top: 5px;
    }
}


/* Container style to ensure content is centered and responsive */
.dev-challenge-container {
  max-width: 100%;
  padding: 20px;
  margin: 0 auto;
  box-sizing: border-box;
  text-align: left;
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

/* Title style for the main heading */
.title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #2a2a2a;
  text-align: left;
}

/* Intro text style */
.intro {
  font-size: 1.3rem;
  color: #555;
  margin-bottom: 20px;
}

/* Call-to-action style */
.cta {
  font-size: 1.4rem;
  font-weight: bold;
  color: #ff5733;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .title {
    font-size: 1.7rem;
  }

  .intro {
    font-size: 1.1rem;
  }

  .cta {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 480px) {
   
  .modal-overlay{
    padding-inline: 2rem;
  }

  .title {
    font-size: 1.5rem;
  }

  .intro {
    font-size: 1rem;
  }

  .cta {
    font-size: 1.1rem;
  }
}


/* Modal.css */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7); /* Transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  animation: fadeIn 0.3s forwards; /* Modal fade-in effect */
  box-shadow: 4px, 4px, 4px,;
}

.modal-content {
  background-color: #fff;
  border-radius: 8px;
  padding: 30px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transform: scale(0.8);
  animation: modalScale 0.3s forwards; /* Scale-up effect */
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  color: #333;
  cursor: pointer;
  transition: color 0.2s;
}

.modal-close:hover {
  color: #e74c3c; /* Red on hover */
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes modalScale {
  to {
    transform: scale(1);
  }
}
