@media screen and (max-width: 300px) {

    .credentialsSectionHeaders {
        padding: 2em;
        text-align: center;
    }

    .credentialsSubHeader {
        font-size: 7vw;
    }

    .credentialsHeader {
        font-size: 8vw !important;
    }

    .credentialsAnchor {
        width: 100%;
    }

    .credentialsItems {
        display: flex;
        flex-direction: column;
        padding: 0 1em;
    }

    .credentialsItemHeader {
        font-size: 8vw;
        text-align: center;
        font-weight: 600;
    }

    .credentialsItemContent {
        font-size: 6vw;
    }

    .credentialsItem img {
        height: 50vw;
    }

    .credentialsItem {
        width: 100% !important;
    }

    .leftSide{
        display: none;
    }

    .rightSide{
        width: 100%;
        height: 155vh;
    }

    .boardContentContainer{
        padding: 0 1em;
    }

    .boardCardsContainer{
        display:flex;
        flex-direction: column;
        align-items: center;
    }

    .boardHeaderContainer{
        width: 100%;
    }

    .boardSubHeader{
        font-size: 3vh;
    }

    .boardCard img{
        height: 20vh;
    }

    .boardCard{
        width: 100vw;
    }

    .boardDescription{
        font-size: 2vh;
    }

    .boardHeader{
        font-size: 4vh;
    }

    .boardCardHeader{
        font-size: 6vw;
        margin: 0;
    }

    .boardCardInfo{
      font-size: 5vw;
    }
}

/* x-small screens - small screens */
@media screen and (min-width: 301px) and (max-width: 767px) {

    .credentialsSectionHeaders {
        padding: 2em;
        text-align: center;
    }

    .credentialsSubHeader {
        font-size: 7vw;
    }

    .credentialsHeader {
        font-size: 3vh !important;
    }

    .credentialsAnchor {
        width: 100%;
    }

    .credentialsItems {
        display: flex;
        flex-direction: column;
    }

    .credentialsItemHeader {
        font-size: 5vw;
        text-align: center;
        font-weight: 600;
    }

    .credentialsItemContent {
        font-size: 5.5vw;
    }

    .credentialsItem img {
        height: 50vw;
    }

    .credentialsItem {
        width: 100% !important;
    }

    .leftSide{
        display: none;
    }

    .rightSide{
        width: 100%;
        height: 1000px;
    }

    .boardSubHeader{
        font-size: 5vw;
    }

    .boardHeader{
        font-size: 6vw;
    }

    .boardDescription{
        font-size: 4vw;
    }

    .boardHeaderContainer{
        width: 80%;
    }

    .boardCardsContainer{
        display:flex;
        flex-direction: column;
        align-items:center ;
    }

    .boardCard{
        gap: 5vh;
        width: 100%;
        text-align: center;
    }

    .boardCard img{
        width: 100%;
        height: 100%;
    }

    .boardCardHeader{
        font-size: 6vw;
    }

    .boardCardInfo{
        font-size: 5vw;
    }


}

@media screen and (min-width: 768px) and (max-width: 1400px) {

    .credentialsSubHeader {
        color: #2c7cc5;
        font-weight: 800;
        font-size: 3vw;
    }

    .credentialsHeader {
        font-size: 3.5vw;
    }

    .boardHeaderContainer{
        width: 75%;
    }

    .boardSubHeader{
        font-size: 3vw;
    }

    .boardHeader{
        font-size: 4vw;
    }

    .boardDescription{
        font-size: 2.5vw;
    }

    .boardCardHeader{
        font-size: 2.5vw;
    }

    .boardCardInfo{
        font-size: 2vw;
    }

    .boardCard{
        width: 100%;
    }
    
    .boardCard img{
    transform: scale(150%);
  }
}

@media screen and (min-width: 768px) and (max-width:1200px){
    .leftSide{
        display: none;
    }

    .rightSide{
        width: 100%;
    }

    iframe{
        width: 1000px;
        height: 1000px;
    }
}