.heroSection {
  height: 120vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  background-image: url("/public/images/home-images/LandingHero.png");
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: 100% 40%;
}

.homeHeroImgContainer {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.homeHeroImgContainer video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.homeHeroImg {
  height: 100%;
  width: 100%;
  z-index: -1;
  filter: brightness(65%);
}

.homeHeroInfoHeader {
  font-size: 6.2vw;
  word-wrap: break-word;
  font-weight: 600;
  text-align: center;
  margin-bottom: 10%;
}

.landingCover {
  width: 100%;
  padding: 2%;
}

.aboutSection {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2%;
  gap: 4em;
  padding: 3em;
}

.aboutSectionText {
  font-size: 2vw;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5em;
}

.lightbulbLaptop {
  width: 50%;
}

.aboutSectionLink {
  font-size: 3rem;
  text-decoration: none;
  color: black;
  cursor: pointer;
  position: relative;
  display: inline-block;
  overflow: hidden;
  &:hover {
    color: #247dff;
  }
}

.aboutSectionLink::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0.2rem;
  background-color: #247dff;
  transition: width 0.5s ease;
}

.aboutSectionLink:hover::after {
  width: 28.8%;
}

.credentialsSection {
  position: relative;
}

.credentialsSectionHeaders {
  text-align: center;
}

.credentialsSubHeader {
  color: black;
  font-weight: 800;
  font-size: 4vw;
  margin: 1% 0;
}

.credentialsHeader {
  font-size: 3.5vw;
  color: #2c7cc5;
  margin: 1% 0;
}

.credentialsImgContainer {
  position: absolute;
  height: 100%;
  z-index: -1;
}

.credentialsImg {
  height: 100%;
  width: 100%;
}

.credentialsItems {
  display: flex;
  padding: 3vw 4vw;
  justify-content: space-evenly;
  width: 90%;
  height: 100%;
  box-sizing: border-box;
  padding: 4em;
  gap: 3em;
}

.credentialsAnchor {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  color: black;
  height: 100%;
}

.credentialsItem {
  width: 20vw;
  background-color: white;
  border: 1px solid #cccccc;
  box-shadow: 0px 2px 2px rgba(38, 38, 38, 0.2);
  position: relative;
  transition: all 0.15s ease-out;
  top: 0;
  &:hover {
    transition: all 0.1s ease-in;
    box-shadow: 0px 8px 16px rgba(38, 38, 38, 0.2);
    transform: scale3d(1.006, 1.006, 1);
  }
}

.credentialsItem,
.credentialsAnchor {
  border-radius: 2%;
}

.credentialsItem img {
  max-height: 14vw;
  border-top-left-radius: 2%;
  border-top-right-radius: 2%;
}

.credentialsItemContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 94%;
  line-height: 1.8;
  justify-content: space-evenly;
  height: 100%;
  margin: 0 auto;
  padding: 0.5em 0;
  font-size: 1.5vw;
  text-align: center;
  gap: 1vw;
}

.credentialsItemHeader {
  font-size: 1.75vw;
  text-align: center;
  font-weight: 600;
}

.credentialsItemExtra {
  color: #5e5e5e;
}

.contactSection {
  width: 100%;
  position: relative;
  display: flex;
}
.contactUsHeader {
  font-size: 2vw;
  font-weight: 600;
}

.leftSide,
.rightSide {
  width: 50%;
}

.rightSide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
}

.rightSide p {
  margin: 0;
}

.contactUs {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.contactUsBg {
  width: 80%;
  padding: 2%;
  margin: 0 6%;
}

.contactUs iframe {
  width: 100%;
}

.boardSection {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3em 0;
}

.boardHeaderContainer {
  text-align: center;
  width: 60vw;
}

.boardDescription {
  font-size: 1.2vw;
}

.boardSubHeader {
  font-weight: 700;
  font-size: 1.5vw;
  color: #2c7cc5;
}

.boardHeader {
  font-size: 2vw;
  font-weight: 700;
}

.boardImgContainer {
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 100%;
  -webkit-transform: scaleX(-1);
  transform: scaleY(-1);
}

.boardImg {
  height: 100%;
  width: 100%;
}

.boardContentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  gap: 3em;
}

.boardCard {
  width: 15vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 2vh;
}

.boardCardsContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 1em;
  padding: 0 3em;
}

.boardCardContentContainer {
  text-align: center;
  margin: 1em 0;
  width: 100%;
  white-space: nowrap;
}

.boardCard img {
  height: 10vw;
  border-radius: 100%;
}

.boardCardHeader {
  font-weight: 600;
  font-size: 1.25vw;
  overflow-wrap: break-word;
}

.boardCardInfo {
  font-size: 1vw;
}

@media screen and (max-width: 1330px) {
  .heroSection {
    height: 100vh;
  }
  .homeHeroInfoHeader {
    margin-bottom: 15%;
  }
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 768px) {
  .heroSection {
    height: 60vh;
    background-size: 100% 30%;
  }
  .homeHeroInfoHeader {
    margin-bottom: 25%;
  }
  .aboutSection {
    padding: 0;
    flex-direction: column;
    gap: 2em;
    margin-top: 5%;
    margin-bottom: 5%;
  }
  .aboutSectionText {
    width: 100%;
    padding: 0 2em;
    font-weight: 500;
    font-size: 1rem;
  }

  .aboutSectionLink:hover::after {
    width: 45%;
  }
}

@media screen and (max-width: 425px) {
  .heroSection {
    height: 50vh;
    background-size: 100% 35%;
  }
}
