
.ethics-page {
    margin-top: 150px;
    padding-left: 20px; 
    padding-right: 20px; 
    max-width: 800px; 
    margin-left: auto; 
    margin-right: auto; 
  }
  
  .ethics-page h1,
  .ethics-page h2,
  .ethics-page h3,
  .ethics-page p,
  .ethics-page ul {
    margin-bottom: 20px; 
  }
  