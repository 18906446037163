

.renewal-policy-page {
    margin-top: 150px; 
    padding-left: 20px; 
    padding-right: 20px; 
    max-width: 800px; 
    margin-left: auto; 
    margin-right: auto; 
  }
  
  .renewal-policy-page h1,
  .renewal-policy-page p,
  .renewal-policy-page ul {
    margin-bottom: 20px; 
  }
  