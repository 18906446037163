.body {
    padding-top: 6vw;
    background-color: #d8d4d4;
  }
  
  .carousel {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 90vh;
  }
  .carouselButtonLeft {
    position: absolute;
    left: 0;
    height: 100%;
    font-size: 50%;
    z-index:10;
  }
  
  .carouselButtonRight{
    position: absolute;
    right: 0;
    height: 100%;
    font-size: 50%;
    z-index:10;
  }
  
  .carouselContent {
    display: flex;
  }
  
  .carouselContent img {
    height: 100%;
    width: 44%;
    padding: 5.8%;
    margin-left: 6%;
  }
  
  .boardInfo {
      margin-top: 4vw;
      margin-left: 16vw;
      height: 50%;
      width: 30%;
  }
  
  .arrow {
    width: 3vw;
  }
  
  button {
    background-color: transparent;
    border: none;
    padding: 0 4em;
  }
  
  .boardInfo h1 {
    font-size: 3.5vw;
  }
  
  .boardInfo h3 {
    font-size: 2.25vw;
    font-weight: 400;
    margin-bottom: 4vw;
  }
  
  .boardInfo p {
    font-size: 1.5vw;
    max-width: fit-content;
  }
  
  .boardBioText {
    width: 140%;
  }
  
  .bottomMenuContent h1 {
    font-size: 1.1rem;
  }
  
  .bottomMenuContent h3 {
    font-size: 1rem;
  }
  
  .bottomMenuContent img {
    width: 8rem;
    margin-top: 10%;
    border-radius: 100%;
  }
  
  .bottomMenu {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    gap: 1em;
    background: rgba(231, 231, 231, 0.61);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(214, 214, 214, 0.19);
    position: sticky;
    bottom: 0;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  
  .bottomMenuContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1%;
  }
  
  .ImgAnimation {
    animation-name: FadeImage;
    animation-duration: 0.5s;
    animation-timing-function: linear;
    position: relative;
  }
  
  @keyframes FadeImage {
    0% {
      opacity: 0;
      left: 50px;
    }
    50% {
      opacity: 50%;
      left: 25px;
    }
    100% {
      opactiy: 100%;
      left: 0;
    }
  }
  
  .HeaderAnimation {
    animation-name: FadeHeader;
    animation-duration: 0.5s;
    animation-timing-function: linear;
    position: relative;
  }
  
  @keyframes FadeHeader {
    0% {
      opacity: 0;
      left: 25px;
    }
  
    100% {
      opactiy: 100%;
      left: 0;
    }
  }
  
  .InfoAnimation {
    animation-name: FadeInfo;
    animation-duration: 0.5s;
    animation-timing-function: linear;
    position: relative;
  }
  
  @keyframes FadeInfo {
    0% {
      opacity: 0;
      top: 25px;
    }
  
    100% {
      opactiy: 100%;
      top: 0;
    }
  }
  
  .AnimateProfile {
    animation-name: ScaleProfile;
    animation-duration: 0.5s;
    animation-timing-function: linear;
    position: relative;
    animation-fill-mode: forwards;
    font-weight: 600;
  }
  
  @keyframes ScaleProfile {
    0% {
      transform: scale(100%);
    }
  
    100% {
      transform: scale(125%);
      font-weight: 600;
    }
  }
  
  @media screen and (max-width: 1330px) {
    .carousel {
      display: flex;
      position: relative;
      width: 100%;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      height: unset;
    }
    .boardInfo h1 {
      font-size: 3vw;
    }
    .boardInfo h3 {
      font-size: 2vw;
      margin-bottom: 2vw;
    }
    .boardInfo p {
      font-size: 1.25vw;
    }
  }
  
  @media screen and (max-width: 1024px) {
      .carouselContent {
          display: flex;
          flex-direction: column;
          align-items: center;
      }
      .carouselContent img {
          height: unset;
          width: 100%;
      }
      .boardInfo {
          margin-left: 0;
          margin-top: 5vw;
          width: 80%;
      }
      .boardInfo h1 {
          font-size: 4vw;
      }
      .boardInfo h3 {
          font-size: 2.5vw;
          margin-bottom: 2vw;
      }
      .boardInfo p {
          font-size: 1.5vw;
      }
      .bottomMenuContent img {
          width: 10vw;
          border-radius: 100%;
      }
      .bottomMenuContent h1 {
          font-size: 2vw;
      }
      .bottomMenuContent h3 {
          font-size: 1.25vw;
      }
      .bottomMenu{
          flex-direction: row;
          align-items: center;
          justify-content: space-around;
          gap: 0;
      }
  }
  
  @media screen and (max-width: 768px) {
        .boardInfo {
          margin-left: 0;
          margin-top: 14vw;
          width: 90%;
      }
  }
  
  @media screen and (max-width: 425px) {
        .boardInfo {
          margin-left: 0;
          margin-top: 20vw;
          width: 95%;
      }
  }
  
