/* Container for the payment option modal */
.payment-option {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 1050;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
}
/* Ensure the modal has no extra margin or padding */
.modal {
  margin: 0;
  padding: 0;
}

/* Ensure the modal body has no extra padding */
.modal-body {
  padding: 0;
}

/* Check the parent elements and ensure they have no extra margin or padding */
.parent-element {
  margin: 0;
  padding: 0;
}


/* Rectangle box for the payment option content */
.payment-option .rectangle {
  background-color: #ffffff;
  border-radius: 10px;
  max-width: 1000px;
  height: auto;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1060; /* Ensure it is above the background overlay */
}

/* Content container for the left and right side with vertical divider */
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Vertical divider styling */
.vertical-divider {
  width: 2px;
  background-color: #000000;
  height: 70vh;
  margin: 0 20px;
}

/* Close button styling */
.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  color: #000000;
  border: none;
  font-size: 24px;
  cursor: pointer;
  font-weight: bold;
  background-color: #ffffff;
  border: 1px solid #4a4a4a;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  padding: 0;
}

/* Left and right side containers */
.left-side,
.right-side {
  width: calc(50% - 20px);
}

/* Payment header styling */
.payment-header {
  margin-bottom: 10px;
  font-family: "Poppins-Bold", Helvetica;
  font-size: 16px;
  font-weight: 700;
}

.price {
  color: #055487;
  font-family: "Poppins-Bold", Helvetica;
  font-size: 36px;
  font-weight: 700;
}

.title {
  color: #000000;
  font-family: "Poppins-Bold", Helvetica;
  font-size: 18px;
  font-weight: 700;
}

/* Invoice details styling */
.invoice-details {
  margin-bottom: 20px;
}

.invoice-header {
  color: #000000;
  font-family: "Poppins-Bold", Helvetica;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
}

.invoice-text,
.details-text {
  color: #999999;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 20px;
}

.details-header,
.invoice-items-header {
  color: #000000;
  font-family: "Poppins-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
}

/* Invoice item container styling */
.invoice-item-container {
  border: 1px solid #d6cdcd;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
}

.invoice-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.item-description {
  color: #999999;
  font-family: "Poppins-Regular", Helvetica;
  font-size: 14px;
  font-weight: 500;
}

.item-price {
  color: #999999;
  font-family: "Poppins-Regular", Helvetica;
  font-size: 14px;
  font-weight: 500;
}

/* Invoice summary styling */
.invoice-summary {
  border: 1px solid #d6cdcd;
  border-radius: 10px;
  padding: 15px;
}

.invoice-summary-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.summary-label {
  color: #000000;
  font-family: "Poppins-Bold", Helvetica;
  font-size: 14px;
}

.summary-value {
  color: #000000;
  font-family: "Poppins-Bold", Helvetica;
  font-size: 14px;
}

.divider {
  border: none;
  border-top: 1px solid #4a4a4a;
  margin: 10px 0;
}

/* Final text styling */
.final-text {
  font-family: "Poppins-Medium", Helvetica;
  font-size: 12px;
  font-weight: 500;
  color: #0070f3;
  text-align: center;
  margin-top: 20px;
}

/* Payment details container */
.payment-details {
  display: flex;
  flex-direction: column;
}

.input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.input-group label {
  font-family: "Poppins-Bold", Helvetica;
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 5px;
}

.input-group input {
  font-family: "Poppins-Medium", Helvetica;
  font-size: 14px;
  padding: 10px;
  border: 1px solid #d6cdcd;
  border-radius: 5px;
 
}

.input-row {
  display: flex;
  gap: 10px; /* Added gap for spacing */
  margin-bottom: 20px; /* Added margin for spacing */
}

.input-row .input-group {
  flex: 1;
}

.form-floating {
  width: 100% !important;
}

/* Terms and conditions styling */
.terms {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.terms input {
  margin-right: 10px;
}

.terms label {
  font-family: "Poppins-Medium", Helvetica;
  font-size: 12px;
  font-weight: 500;
}

/* Confirm payment button styling */
.confirm-button {
  font-family: "Poppins-Bold", Helvetica;
  font-size: 14px;
  padding: 10px 20px;
  border: none;
  background-color: #055487;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

/* Media query */
@media (max-width: 768px) {
  .payment-option {
    align-items: flex-start;
  }

  .payment-option .rectangle {
    width: 95%;
    padding: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    height: auto;
    max-height: 100vh;
  }

  .content {
    flex-direction: column;
  }

  .left-side,
  .right-side {
    width: 100%;
  }

  .vertical-divider {
    display: none;
  }

  .invoice-summary,
  .invoice-item-container {
    padding: 10px;
  }

  .close-button {
    top: 10px;
    left: 10px;
    width: 28px;
    height: 28px;
    font-size: 20px;
  }

  .payment-header,
  .details-header,
  .invoice-header,
  .invoice-items-header {
    font-size: 14px;
  }

  .price {
    font-size: 28px;
  }

  .title {
    font-size: 16px;
  }

  .summary-label,
  .summary-value {
    font-size: 12px;
  }

  .item-description,
  .item-price {
    font-size: 12px;
  }

  .input-group label,
  .input-group input {
    font-size: 12px;
  }

  .confirm-button {
    font-size: 12px;
    padding: 8px 16px;
  }

  .final-text {
    font-size: 10px;
  }

  .terms label {
    font-size: 10px;
  }
}
